import AlekhJohari from "../../assets/advisor-pictures/alekh-johari.jpg";
import AnkushMittal from "../../assets/advisor-pictures/drankush.jpg";
import ManiMadhukar from "../../assets/advisor-pictures/drmani.jpg";
import RameshShankarS from "../../assets/advisor-pictures/ramesh-shankar-s.jpg";
import AbhishekGupta from "../../assets/advisor-pictures/abhishek-gupta.jpg";
import ParthaPratimRoy from "../../assets/team-pictures/Partha Pratim Roy.jpg";
import AnujGarg from "../../assets/team-pictures/anuj.png";
import AnmolGupta from "../../assets/team-pictures/Anmol Gupta.jpg";
import VishalPandey from "../../assets/team-pictures/Vishal Pandey.png";
import AvinashJain from "../../assets/team-pictures/avinash.png";
import ContactForm from "../ContactForm/ContactForm";
import GalaxyPortalLink from "../Galaxy/GalaxyPortalLink";
import linkedin from "../../assets/linkedin logo.png";

import GalaxySlider from "../GalaxySlider/GalaxySlider";

const PageContent = [
  {
    title: "ABOUT",
    element: function () {
      return (
        <p>
          <span style={{ fontWeight: "bold" }}>Mission</span>
          <br />
          "Our mission is a dual pursuit of enlightenment and empowerment. With VRPlaced, we're transforming the educational landscape, merging learning with the captivating allure of virtual reality. EmpoVR is our commitment to wellness, offering a sanctuary within the digital sphere where productivity harmonizes with tranquility."
          <br />
          <br />
          <span style={{ fontWeight: "bold" }}>Vision</span>
          <br />
          "We envision a world where every individual can access the treasures of India's cultural wisdom in a space that transcends time—a sanctuary where the pursuit of knowledge and well-being is not just a promise but a daily reality. MythyaVerse is your gateway to this new era of discovery and growth."
          <br />
          <br />
          <span style={{ fontWeight: "bold" }}>The Essence of MythyaVerse</span>
          <br />
          "Derived from 'Mithya'—the Sanskrit for myth, and 'Verse'—short for universe, our name is a beacon guiding users to a world where the legendary and the futuristic coalesce. Our offerings, VRPlaced and EmpoVR, are the vehicles through which we deliver on the promise of a versatile, transformative experience."

        </p>
      );
    },
  },
  {
    title: "PRODUCTS",
    element: function () {
      const links = [
        {
          element: (
            <GalaxyPortalLink
              productName="VRPlaced"
              productID="VRPlaced"
              scale={0.6}
              colorOne="#7debfd"
              colorTwo="#f3f3f3"
            />
          ),
          caption: "VRPlaced",
          link: "/transition?target=VRPlaced",
        },
        {
          element: (
            <GalaxyPortalLink
              productName="EmpoVR"
              productID="EmpoVR"
              scale={0.6}
              colorOne="#ff8600"
              colorTwo="#ffffff"
            />
          ),
          caption: "EmpoVR",
          link: "/transition?target=EmpoVR",
        },
        {
          element: (
            <GalaxyPortalLink
              productName="Spiritual Wellness"
              productID="mentalHealth"
              scale={0.6}
              colorOne="#b7fe00"
              colorTwo="#469f77"
            />
          ),
          caption: "Spiritual Wellness",
          link: "/transition?target=mentalHealth",
        },
        {
          element: (
            <GalaxyPortalLink
              productName="Blockchain as a Service"
              productID="BaaS"
              scale={0.6}
              colorOne="#b7fe00"
              colorTwo="#469f77"
            />
          ),
          caption: "Blockchain as a Service",
          link: "/transition?target=BaaS",
        },
      ];

      return (
        <div className="">
          <GalaxySlider items={links} />
        </div>
      );
    },
  },
  {
    title: "SERVICES",
    element: function () {
      const links = [
        {
          element: (
            <GalaxyPortalLink
              productName="AI Consultancy"
              productID="AIConsultancy"
              scale={0.6}
              colorOne="#469f77"
              colorTwo="#b7fe00"
            />
          ),
          caption: "AI Consultancy",
          link: "/transition?target=AIConsultancy",
        },
        {
          element: (
            <GalaxyPortalLink
              productName="XR Consultancy"
              productID="XRConsultancy"
              scale={0.6}
            />
          ),
          caption: "XR Consultancy",
          link: "/transition?target=XRConsultancy",
        },      
      ];

      return (
        <div className="">
          <GalaxySlider items={links} />
        </div>
      );
    },
  },
  {
    title: "TEAM",
    element: function () {
      
      const TeamMember = ({ photo, name, designation, qualification, social }) => {
        return (
          <div className="flex items-center lg:p-5 py-2 md:mb-0 mb-10">
            <img src={photo} className="rounded-full w-[100px] aspect-square" alt={`${name}'s photo`} />
            <div className="flex flex-col border-4 border-white border-t-0 border-l-0 rounded-br-3xl p-4">
              <div className="flex items-center">
                <h2 className="font-bold text-lg mr-2">{name}</h2>
                {social && (
                  <a href={social} target="_blank" rel="noopener noreferrer">
                    <img src={linkedin} alt="LinkedIn" className="w-4 h-4" />
                  </a>
                )}
              </div>
              <p className="xl:w-64 lg:w-56 md:w-44 w-48 max-[375px]:w-26 max-[320px]:w-32 font-semibold">
                {designation}
              </p>
              <p className="xl:w-64 lg:w-56 md:w-44 w-48 max-[375px]:w-26 max-[320px]:w-32">
                {qualification}
              </p>
            </div>
          </div>
        );
      };
      
      return (
        <>
          <h1 className="text-4xl mb-5">Our Team</h1>
          <div className="flex md:flex-row flex-col lg:justify-around justify-between">

            <TeamMember
              photo={AnmolGupta}
              name="Anmol Gupta"
              designation={"CEO"}
              qualification={"PhD, IIT Roorkee | RuG Netherlands"}
              social={"https://www.linkedin.com/in/anmolscd/"}
            />
            <TeamMember
              photo={VishalPandey}
              name="Vishal Pandey"
              designation={"CTO"}
              qualification={"PhD, IIT Roorkee"}
              social={"https://www.linkedin.com/in/vmyth/"}
            />
          </div>
          <div className="flex md:flex-row flex-col lg:justify-around justify-between">
            <TeamMember
              photo={ParthaPratimRoy}
              name="Partha Pratim Roy"
              designation={"Mentor"}
              qualification={"Associate Professor, CSE, IIT Roorkee"}
              social={"https://www.linkedin.com/in/dr-partha-pratim-roy-ab1144238/"}
            />
            <TeamMember
              photo={AnujGarg}
              name="Anuj Garg"
              designation={"Technical Advisor"}
              qualification={"Vice President - Engineering, ZebPay"}
              social={"https://www.linkedin.com/in/anujkgarg/"}
            />
          </div>
          <div className="flex md:flex-row flex-col lg:justify-around justify-between">
            <TeamMember
              photo={AvinashJain}
              name="Avinash Jain"
              designation={"Technical Advisor"}
              qualification={"Associate Director, Kyndryl"}
              social={"https://www.linkedin.com/in/avinash-jain-852b0021a/"}
            />
          </div>
        </>
      );
    },
  },
  {
    title: "ADVISORS",
    element: function () {
      const Advisor = ({ photo, name, designation, social }) => {
        return (
          <div className="flex items-center lg:p-5 py-2 md:mb-0 mb-10">
            <img src={photo} className="rounded-full w-[100px] aspect-square" alt={`${name}'s photo`} />

            <div className="flex flex-col border-4 border-white border-t-0 border-l-0 rounded-br-3xl p-4">
              <div className="flex items-center">
                <h2 className="font-bold text-lg mr-2">{name}</h2>
                {social && (
                  <a href={social} target="_blank" rel="noopener noreferrer">
                    <img src={linkedin} alt="LinkedIn" className="w-4 h-4" />
                  </a>
                )}
              </div>
              <p className="xl:w-64 lg:w-56 md:w-44 w-48 max-[375px]:w-26 max-[320px]:w-32">
                {designation}
              </p>
            </div>
          </div>
        );
      };
      return (
        <>
          <h1 className="text-4xl mb-5">Our Advisors</h1>
          <div className="flex md:flex-row flex-col lg:justify-around justify-between">
            <Advisor
              photo={AlekhJohari}
              name="Alekh Johari"
              designation={`Founder: Anemoi Solution | Creator: Show called 'The Future of WEB' | Creator: Metaverse Marathon`}
              social={"https://www.linkedin.com/in/alekh-johari/"}
            />
            <Advisor
              photo={RameshShankarS}
              name="Ramesh Shankar S"
              designation={"Chief Joy Officer: Hrishti.com | Ex. Executive Vice President & Head of HR (South Asia Cluster) - Siemens"}
              social={"https://www.linkedin.com/in/srameshshankar/"}
            />
          </div>
          <div className="flex md:flex-row flex-col lg:justify-around justify-between">
            <Advisor
              photo={AbhishekGupta}
              name="Abhishek Gupta"
              designation={"Head of Human Resources, ZebPay | ET NOW's 'Young HR Leader of the Year' | SCMHRD, Pune"}
              social={"https://www.linkedin.com/in/abhishek-gupta-04905b29/"}
            />
            <Advisor
              photo={AnkushMittal}
              name="Dr. Ankush Mittal"
              designation={"Vice Chancellor (COER Univ., Roorkee), Adjunct Professor (IIT Mandi)"}
              social={"https://www.linkedin.com/in/drankushmittal/"}
            />
          </div>
          <div className="flex md:flex-row flex-col lg:justify-around justify-between">
            <Advisor
              photo={ManiMadhukar}
              name="Dr. Mani Madhukar"
              designation={"Program Lead, SkillsBuild, IBM India Private Limited"}
              social={"https://www.linkedin.com/in/manimadhukar/"}
            />
          </div>
        </>
      );
    },
  },
  {
    title: "CONTACT",
    element: function () {
      return (
        <>
          You can contact us at:
          <br />
          <br />
          <ul>
            <li className="mb-2">
              contact@mythyaverse.com | ceo@mythyaverse.com
            </li>
            <li className="mb-2">
              vishal@mythyverse.com
            </li>
          </ul>
          <br />
          Our Location:
          <ContactForm/>
        </>
      );
    },
  },
];

export default PageContent;
