import React, { useState, useEffect, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { extend } from "@react-three/fiber";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import Page from "../components/ScrollingPages/PageLegend";
import PageScroller from "../components/ScrollingPages/PageScroller";
import PageContent from "../components/ScrollingPages/PageConent";
import { MeshLine, MeshLineMaterial } from "../components/SparkStorm/MeshLine";
import logo from "../assets/mythyaverse-logo-glow.png";
import soundEffect2 from "../assets/audio-effects/effect-2.mp3";
import RehoboamComponent from "../components/Rehoboam/Rehoboam";

extend({ MeshLine, MeshLineMaterial, OrbitControls });

function MobileNavbar({ currentIndex, setCurrentIndex }) {
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  const toggleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen);
  }

  const handleNavClick = (index) => {
    setCurrentIndex(index);
    setIsNavbarOpen(false);
  };

  return (
    <nav className="lg:hidden block">
      <div className="text-right pr-5">
        <button onClick={toggleNavbar} className="text-white text-lg">
          <FontAwesomeIcon icon={faBars} />
        </button>
      </div>
      <div className={`${isNavbarOpen ? "block" : "hidden"} lg:hidden bg-black transition duration-300`} id="mobile-menu">
        <div className="px-2 pt-2 pb-3 space-y-1 absolute left-0 top-14 z-40 bg-black w-full rounded-xl backdrop-blur-sm bg-opacity-50 flex flex-col items-center">
          {['About', 'Products', 'Services', 'Services', 'Advisor', 'Contact'].map((label, index) => (
            <button
              key={index}
              onClick={() => handleNavClick(index)}
              className="block w-full px-3 py-2 text-base font-medium text-white focus:outline-2 outline-white hover:border-2 border-white"
            >
              {label}
            </button>
          ))}
        </div>
      </div>
    </nav>
  );
}

export default function ContentPage() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const totalIndexes = PageContent.length;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const targetSectionIndex = searchParams.get("section");

  useEffect(() => {
    if (targetSectionIndex && targetSectionIndex < totalIndexes && targetSectionIndex > -1) {
      _handleIndexChange(targetSectionIndex);
    }
  }, [targetSectionIndex]);

  const _handleIndexChange = (index) => {
    const newIndex = Math.min(Math.max(index, 0), totalIndexes - 1);

    if (newIndex !== currentIndex) {
      playEffectSound(soundEffect2);
      setCurrentIndex(newIndex);
      window.history.replaceState(
        null,
        "MythyaVerse - Where Unreal becomes Real",
        "/content?section=" + newIndex
      );
      setSubMenuState(false);
    }
  };

  let scrollCount = 0;

  const _handlePageScroll = (e) => {
    const delta = Math.sign(e.deltaY);
    scrollCount += Math.abs(e.deltaY);
    if (scrollCount > 400) {
      const newIndex = currentIndex + delta;
      if (newIndex >= 0 && newIndex < totalIndexes) {
        _handleIndexChange(newIndex);
      }
      scrollCount = 0; // Reset scroll count after page change
    }
  };

  const playEffectSound = (effectAudio) => {
    const effect = new Audio(effectAudio);
    effect.play();
  };

  const lockScroll = useCallback(() => {
    document.body.style.overflow = "hidden";
  }, []);

  const unlockScroll = useCallback(() => {
    document.body.style.overflowY = "scroll";
  }, []);

  const [subMenuState, setSubMenuState] = useState(false);

  const toggleSubMenu = () => {
    setSubMenuState(!subMenuState);
  };

  const isMobileDevice = () => {
    return window.innerWidth <= 1024;
  };

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(isMobileDevice());
    };

    handleResize(); // Initial check

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  lockScroll();

  return (
    <div>
      <header id="header" className="scaled-header flex justify-between items-center relative">
        <Link to="/" className="relative z-30 text-2xl  text-white font-bold flex items-center">
          <img src={logo} className="md:h-16 min-[375px]:h-16 h-10" alt="Logo" />
          <h1 id="title">MYTHYAVERSE</h1>
        </Link>
        {isMobile && <MobileNavbar currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} />}
      </header>
      <main className="relative h-screen z-40 md:ml-5 ml-0 flex items-start" onWheel={_handlePageScroll}>
        <div className={`${currentIndex === 0 || currentIndex === 5 ? "xl:w-[60%]" : "w-full"} px-5 flex mt-28 justify-start`}>
          <div className="lg:flex hidden h-fit min-w-[157px]">
            <PageScroller
              orientation={"vertical"}
              onChange={_handleIndexChange}
              currentIndex={currentIndex}
              subMenuState={subMenuState}
            />
            <Page
              currentIndex={currentIndex}
              className="w-1/4"
              handler={_handleIndexChange}
              toggleSubMenu={toggleSubMenu}
              subMenuState={subMenuState}
            />
          </div>
          <div className={`${currentIndex === 1 ? "max[425px]:pr-7 max[425px]:pl-3 pr-0 pl-0" : "pr-7 pl-3"} 2xl:px-20 xl:px-10 lg:px-10 md:h-full h-[75vh] w-full text-white text-justify overflow-y-auto overflow-x-hidden custom-scrollbar`}>
            {PageContent[currentIndex].element()}
          </div>
        </div>
        {!isMobile && currentIndex === 0 && (
          <div className="xl:block hidden mt-20">
            <RehoboamComponent />
          </div>
        )}
      </main>
      <div className="mousey mb-4">
        <div className="scroller"></div>
      </div>
    </div>
  );
}