import { initializeApp } from "firebase/app";
import { useState } from "react";
import { getFirestore, collection, addDoc } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyA6ijwhhpA-b0JWwPxbO2lBZJORCx2ceQE",
  authDomain: "mythyaverse-web.firebaseapp.com",
  projectId: "mythyaverse-web",
  storageBucket: "mythyaverse-web.appspot.com",
  messagingSenderId: "734555658580",
  appId: "1:734555658580:web:e4c60446667a75d62f10c1",
  measurementId: "G-DW5KLNWPK7",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const ContactForm = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const entriesCol = collection(db, "contact-form");
    await addDoc(entriesCol, {
      email,
      message,
    });
    alert("Your message was sent successfully!");
    setEmail("");
    setMessage("");
  };

  return (
    <div className="mt-2">
    {/* <form onSubmit={handleFormSubmit} className="my-5 w-full">
      <label>
        Email:
        <input
          type="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          required
          className="block p-2 mb-5 bg-transparent border-2 border-white md:w-[60%] w-full"
        />
      </label>
      <label>
        Message:
        <textarea
          value={message}
          onChange={(event) => setMessage(event.target.value)}
          required
          rows={5}
          className="block p-2 mb-5 bg-transparent border-2 border-white md:w-[60%] w-full"
        />
      </label>
      <button
        type="submit"
        className="mt-5 bg-white text-black text-sm p-4 hvr-sweep-to-right"
      >
        Submit
      </button>
    </form> */}
    <iframe
  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3507.52449906066!2d77.4923506!3d28.463746999999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cc1debfffffff%3A0xa3aa188cced4d915!2sGNEC%20IIT%20Roorkee!5e0!3m2!1sen!2sin!4v1716757765909!5m2!1sen!2sin"
  width="400"
  height="300"
  style={{ border: "0" }}
  allowFullScreen=""
  loading="lazy"
  referrerPolicy="no-referrer-when-downgrade"
></iframe>
    </div>
  );
};

export default ContactForm;
