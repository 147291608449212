import React from 'react';
import styles from './HoverButton.module.css';
import { Link } from 'react-router-dom';


const HoverButton = ({onClick, to, text}) => {
  return (
    <div className={styles.wrapper}>
      <Link to={to} onClick={onClick} className={styles.button}>
        {text}
        {/* <span className={styles.span}></span>
        <span className={styles.span}></span>
        <span className={styles.span}></span>
        <span className={styles.span}></span> */}
      </Link>
    </div>
  );
};

export default HoverButton;
