import { Link, useLocation } from "react-router-dom";
import logo from "../assets/mythyaverse-logo-glow.png";
import Carousel from "../components/Carousel/Carousel";
import Wormhole from "../components/Wormhole/Wormhole";
import "./style.css";

import { useEffect, useState } from "react";
import thumbnail1 from "../assets/thumbnails/1.jpg";
import thumbnail2 from "../assets/thumbnails/2.jpg";
import thumbnail3 from "../assets/thumbnails/3.jpg";
import thumbnail4 from "../assets/thumbnails/4.jpg";
import thumbnail5 from "../assets/thumbnails/baas.png"

export default function ProductsPage() {
  const ProductList = {
    VRPlaced: {
      data: [
        {
          title: "VRPlaced",
          content: `VRPlaced is a revolutionary career readiness platform, uniquely designed to navigate the challenges of a rapidly evolving, AI-driven job market. VRPlaced goes beyond traditional interview preparation, offering immersive, realistic training as a core aspect of its comprehensive professional development program. This cutting-edge tool not only reduces interview anxiety but also enhances critical life skills like public speaking and confidence. Catering to a diverse audience, VRPlaced empowers both students and professionals to develop the technical and soft skills essential in today's dynamic workplace. It stands out as the only comprehensive solution for candidates to excel in AI-based hiring processes and thrive in a technologically transformed job landscape, ensuring that candidates are equipped for every stage and step of their career journey.
          `,
        },
      ],

      videos: [
        {
          thumbnail: thumbnail4,
          link: "https://youtu.be/VTCPbe-_V-A",
          caption: "VRPlaced",
        },
      ],
    },

    BaaS: {
      data: [
        {
          title: "Blockchain as a Service",
          content: `<strong>Key Features</strong><br/>
            <li>Automated Workflows: Simplify multi-organizational processes without coding.</li>
            <li>Data Security: Protect data with encryption and controlled access.</li>
            <li>Transparent Operations: Ensure traceability with immutable audit trails.</li>
            <li>Workflow Analytics: Optimize performance with KPI dashboards.</li>
            <li>Global Support: Multi-language and multi-timezone functionality.</li>
          <br/>
          <strong>Business Benefits</strong><br/>
          
            <li>Regulatory Compliance: Maintain transparency and accountability.</li>
            <li>Increased Productivity: Automate tasks to enhance efficiency.</li>
            <li>Customer Satisfaction: Improve trust with reliable, traceable systems.</li>
          <br/>
          <strong>Use Cases</strong><br/>
          
            <li>Supply Chain Management: Enhance traceability and inventory management.</li>
            <li>Manufacturing: Streamline production and defect management.</li>
          `,
        },
      ],

      videos: [
        {
        thumbnail: thumbnail5,
        link: "https://drive.google.com/file/d/1hxvEI6JCu4wE5CrkElzy7PISnfNHmuwl/preview",
        caption: "Crafti Blockchain",
        }
      ],
    },

    

    EmpoVR: {
      data: [
        {
          title: "EmpoVR",
          content: `
          EmpoVR is an innovative solution developed by Mythyaverse that combines the power of Virtual Reality (VR) and Artificial Intelligence (AI) to promote holistic wellness among employees. The platform offers immersive experiences across five pivotal dimensions of wellness: 
              <li>Mental Wellness through guided meditation environments.</li>
              <li>Physical Wellness via VR workouts tailored to individual needs.</li>
              <li>Intellectual Enhancement with cognitive games and AI-assisted skill-enhancement modules.</li>
              <li>Social Connectivity using multiplayer modes for meetings and cooperative games/workouts.</li>
              <li>Financial Acumen through AI-driven gamified scenarios aiding in informed financial decision-making.</li>
            
          `,
        },
      ],
      videos: [
        {
          thumbnail: thumbnail1,
          link: "https://drive.google.com/file/d/19hsIyHsoHs05M5ia-buu9dLWoQSYk42C/preview",
          caption: "EmpoVR",
        },
      ],
    },
    
    mentalHealth: {
      data: [
        {
          title: "Spiritual Wellness",
          content: `Spiritual Wellness by MythyaVerse invites you to a tranquil fusion of tradition and technology, immersing you in India's rich spiritual tapestry. Experience ancient rituals through 360° temple tours, converse with lifelike 3D avatars of mythological figures, and engage with the sacred stories of Ramayan and Mahabharat brought to life in interactive, gamified narratives. This serene digital sanctuary offers a unique gateway to mindfulness and cultural reverence, all within the reach of virtual reality.`,
        },
      ],

      videos: [
        {
          thumbnail: thumbnail2,
          link: "https://drive.google.com/file/d/1leKq9QfCPLXvKD8bp_EnKOS_vxadIiov/preview",
          caption: "Virtual Tour Khatu Shyam Delhi",
        },
        {
          thumbnail: thumbnail3,
          link: "https://drive.google.com/file/d/1dmcG57v2IYaIGs25_qFBlWDbGUur136W/preview",
          caption: "Ram Updesh",
        },
      ],
    },
  };

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const targetProduct = searchParams.get("name");

  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);

  // prevent scrollIntoView from being called at component mount by delaying it by 1 second
  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true);
    }, 1000);
  }, []);

  useEffect(() => {
    if (isLoaded && targetProduct === "others") {
      const element = document.getElementById(`product-${currentSlideIndex}`);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
        });
      }
    }
  }, [currentSlideIndex]);

  return (
    <div className="flex flex-col h-screen overflow-hidden">
      <header id="header" className="scaled-header scaled-header-products">
        <Link
          to="/"
          className="relative z-30 text-2xl text-white font-bold flex items-center"
        >
          <img
            src={logo}
            style={{ height: "4rem" }}
            alt="mythyaverse logo"
          ></img>
          <h1 id="title">MYTHYAVERSE</h1>
        </Link>
      </header>
      <div
        className={`xl:flex xl:overflow-hidden overflow-auto items-center ${
          targetProduct === "others" ? "h-screen" : "h-screen"
        }`}
      >
        <div
          className={`xl:w-1/3 xl:mt-0 mt-10 w-full flex flex-col items-start text-white px-10 h-5/6 product-content-panel 
          
          `}
        >
          {ProductList[targetProduct].data.map((product, index) => {
            return (
              <>
  <h1 className="text-4xl mb-10" id={`product-${index}`}>
    {product.title}
  </h1>
  <div
    dangerouslySetInnerHTML={{ __html: product.content }}
    className="mb-10 p-5 text-justify text-lg content-box"
  />

              </>
            );
          })}

          <Link
            to={`/content?section=${1}`}
            className="bg-white text-black text-sm p-4 lg:mt-0 mt-10 hvr-sweep-to-right"
          >
            {"<"} Go back
          </Link>
        </div>
        <div className="xl:w-2/3 w-full z-20">
          <Carousel
            items={ProductList[targetProduct].videos}
            callbackCurrentIndex={(i) => {
              setCurrentSlideIndex(i);
            }}
          />
        </div>
        <div className="absolute right-10 bottom-0 top-0">
          <Wormhole isStaticVertical={true} className="z-10" />
        </div>
      </div>
    </div>
  );
}
