import { TransitionGroup, CSSTransition, SwitchTransition } from "react-transition-group";
import { useLocation, Routes, Route } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

import Home from "./pages/Home";
import ContentPage from "./pages/Content";
import ProductsPage from "./pages/Products";
import ServicesPage from "./pages/Services";
import PrivacyPolicy from "./pages/PrivacyPolicy";

import "./App.css";
import WormholeTransitionPage from "./pages/WormholeTransitionPage";
import bgMusic from "./assets/audio-effects/bg-music.mp3";
import ResumeAnalyser from "./pages/ResumeAnalyser";

export default function App() {
  const location = useLocation();
  const [isPlaying, setIsPlaying] = useState(false);
  const [muted, setMuted] = useState(false);
  const musicRef = useRef(null);

  const style = {
    background: `linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url("/bg-image.jpg")`,
    backgroundSize: "contain",
    width: "100%",
    height: "100%",
    position: "fixed",
  };

  const bodyRef = useRef(null);

  function playBGMusic() {
    if (!musicRef.current) return;
    musicRef.current.volume = 0.2;
    if (!isPlaying) {
      setIsPlaying(true);
      musicRef.current.play();
    }
  }

  function handleMute() {
    if (!musicRef.current) return;
    musicRef.current.muted = !musicRef.current.muted;
    setMuted(musicRef.current.muted);
  }

  useEffect(() => {
    const handleMouseMove = (event) => {
      const { clientX, clientY } = event;
      bodyRef.current.style.backgroundPosition = `${clientX / 20}px ${clientY / 20}px`;
    };
    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <div style={style} ref={bodyRef}>
      <TransitionGroup>
        <div className="ball-of-light"></div>
        <CSSTransition key={location.key} classNames="slide" timeout={700}>
          <SwitchTransition>
            <Routes location={location}>
              <Route path="/" exact element={<Home musicTrigger={playBGMusic} />} />
              <Route path="/content" exact element={<ContentPage />} />
              <Route path="/resumeAnalyser" exact element={<ResumeAnalyser />} />
              <Route path="/products" exact element={<ProductsPage />} />
              <Route path="/services" exact element={<ServicesPage />} />
              <Route path="/transition" exact element={<WormholeTransitionPage />} />
              <Route path="/privacypolicies/meta-buildingblocks" exact element={< PrivacyPolicy/>} />

            </Routes>
          </SwitchTransition>
        </CSSTransition>
        <audio src={bgMusic} ref={musicRef}></audio>
        <button onClick={handleMute} className="mute-button text-white fixed z-50">
          <div className={`mute-icon-${muted ? "static" : "animated"}`}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>
      </TransitionGroup>
    </div>
  );
}
