import React from 'react';


//http://mythyaverse.com/privacypolicies/
const PrivacyPolicy = () => {
  return (
    <div className="flex mb-10 justify-center items-center p-5 pb-5 shadow-lg">
      <div className=" text-justify text-lg content-box text-white p-10 rounded-lg shadow-lg max-w-screen max-h-screen w-full overflow-auto">
        <h1 className="text-4xl mb-6 text-center" id="privacy-policy">
          Privacy Policy for Building Blocks
        </h1>
        <p className="mb-4">Effective Date: 1 June 2024</p>
        <p className="mb-4">
          MythyaVerse Private Limited ("we", "our", or "us") operates the mixed reality app "Building Blocks" (the "App"), which is available on the Meta Store (App Lab). We are committed to protecting your privacy. This Privacy Policy outlines our practices concerning the collection, use, and disclosure of information when you use our App.
        </p>
        <h2 className="text-2xl mb-4">Information Collection and Use</h2>
        <p className="mb-4">
          We do not collect any personal information or data from users of our App. You can use our App without providing any personal information.
        </p>
        <h2 className="text-2xl mb-4">Data Collection Methods</h2>
        <p className="mb-4">
          Our App does not use any means to collect personal or technical data from users.
        </p>
        <h2 className="text-2xl mb-4">Data Sharing and Disclosure</h2>
        <p className="mb-4">
          Since we do not collect any personal data, we do not share or disclose any user information with third parties.
        </p>
        <h2 className="text-2xl mb-4">User Rights</h2>
        <p className="mb-4">
          As we do not collect any personal data, there are no data-related rights applicable to our users, such as access, correction, or deletion of data.
        </p>
        <h2 className="text-2xl mb-4">Data Security</h2>
        <p className="mb-4">
          Even though we do not collect personal data, we are committed to ensuring that any interactions with our App are secure. We implement appropriate technical and organizational measures to protect any data that may be indirectly related to the use of our App.
        </p>
        <h2 className="text-2xl mb-4">Data Retention</h2>
        <p className="mb-4">
          Since no personal data is collected, there is no data retention policy in place.
        </p>
        <h2 className="text-2xl mb-4">Changes to This Privacy Policy</h2>
        <p className="mb-4">
          We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy within the App. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted within the App.
        </p>
        <h2 className="text-2xl mb-4">Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact us at:
          <br />
          MythyaVerse Private Limited
          <br />
          Email: <a href="mailto:contact@mythyaverse.com" className="text-blue-400">contact@mythyaverse.com</a>
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
