import { Link, useLocation } from "react-router-dom";
import logo from "../assets/mythyaverse-logo-glow.png";
import Carousel from "../components/Carousel/Carousel";
import Wormhole from "../components/Wormhole/Wormhole";
import "./style.css";

import { useEffect, useState } from "react";
import thumbnail5 from "../assets/thumbnails/5.jpg";
import thumbnail6 from "../assets/thumbnails/6.jpg";
import thumbnail7 from "../assets/thumbnails/7.jpg";
import thumbnail8 from "../assets/thumbnails/8.jpg";
import thumbnail9 from "../assets/thumbnails/9.jpg";

export default function ServicesPage() {
    const ServicesList = {
      AIConsultancy: {
        data: [
          {
            title: "AI Consultancy",
            content: `<strong>AI Consultancy: Chatbots and Assistants </strong><br/>
            <li>Our AI consultancy services harness the power of artificial intelligence to create advanced chatbots and digital assistants tailored to your business needs. These AI-driven solutions enhance customer interactions, streamline communications, and automate support, enabling your organization to stay ahead in a technologically evolving landscape.
            </li>
            <strong>Key Features</strong><br/>
            <li>Custom AI Chatbots: Build bespoke chatbots for seamless customer service and engagement across multiple platforms including social media and messaging apps.</li>
            <li>Intelligent Assistants: Develop sophisticated AI assistants capable of understanding and executing complex tasks, providing support and enhancing productivity within your workforce.</li>
            <li>Scalable Solutions: Designed to grow with your business, our AI tools can handle increasing interactions and complex queries with ease.</li>
            <br/>
            <strong>Business Benefits</strong><br/>
            <li>Enhanced Customer Engagement: Improve interaction quality with 24/7 automated support.</li>
            <li>Operational Efficiency: Reduce response times and free up resources with AI automation.</li>
            <li>Data Insights: Gather valuable customer data from interactions to better understand needs and preferences.</li>
            <br/>`,
          },
        ],
  
        videos: [
          {
            thumbnail: thumbnail5,
            link: "https://youtu.be/JdpPqXYWJqM",
            caption: "Custom AI Chatbots",
          },
          {
            thumbnail: thumbnail6,
            link: "https://drive.google.com/file/d/1tGgKMmHzKd07SgxcrBPRyAd2dzBfQ9Kc/preview",
            caption: "Inteligence Assistance",
          },
        ],
      },
      XRConsultancy: {
        data: [
          {
            title: "XR Consultancy",
            content: `<strong>XR Consultancy: VR & 360 Walkthroughs, Metaverse, Product Demos, Configurators</strong><br/>
            <li>Explore new dimensions of digital interaction with our XR consultancy services. From virtual reality experiences to comprehensive Metaverse integrations, our solutions are designed to transport users into immersive environments that enhance understanding, engagement, and interaction.
            </li>
            <strong>Key Features</strong><br/>
            <li>VR & 360 Walkthroughs: Offer virtual tours and immersive content that provide a realistic sense of space and environment, ideal for real estate, education, and more.</li>
            <li>Metaverse Development: Build unique, scalable virtual worlds where users can interact, learn, and explore new opportunities.</li>
            <li>Product Demos and Configurators: Utilize VR to demonstrate product features dynamically and create configurable experiences that allow customers to customize products in real-time.</li>
            <br/>
            <strong>Business Benefits</strong><br/>
            <li>Immersive Customer Experiences: Engage users with visually rich and interactive environments.</li>
            <li>Enhanced Product Understanding: Showcase products in detail, improving customer decision-making.</li>
            <li>Innovative Marketing Tools: Use cutting-edge technology to stand out in the market, attracting and retaining tech-savvy consumers.</li>
            <br/>`,
          },
        ],
  
        videos: [
          {
            thumbnail: thumbnail7,
            link: "https://drive.google.com/file/d/1gEBfLKZu9U4BgOJDNBkIgaDY5j78tmYN/preview",
            caption: "Car Customization",
          },
          {
            thumbnail: thumbnail8,
            link: "https://youtu.be/cxk1n8AiCj8",
            caption: "Rock Museum IITR",
          },
          {
            thumbnail: thumbnail9,
            link: "https://drive.google.com/file/d/1gdcPkJrxINjM30Dn_1__TAMIpktpsbcU/preview",
            caption: "Teqbot Product Demo",
          },
        ],
      },
      

    };
  
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const targetServices = searchParams.get("name");
  
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);
  
    // prevent scrollIntoView from being called at component mount by delaying it by 1 second
    useEffect(() => {
      setTimeout(() => {
        setIsLoaded(true);
      }, 1000);
    }, []);
  
    useEffect(() => {
      if (isLoaded && targetServices === "others") {
        const element = document.getElementById(`product-${currentSlideIndex}`);
        if (element) {
          element.scrollIntoView({
            behavior: "smooth",
          });
        }
      }
    }, [currentSlideIndex]);
  
    return (
      <div className="flex flex-col h-screen overflow-hidden">
        <header id="header" className="scaled-header scaled-header-products">
          <Link
            to="/"
            className="relative z-30 text-2xl text-white font-bold flex items-center"
          >
            <img
              src={logo}
              style={{ height: "4rem" }}
              alt="mythyaverse logo"
            ></img>
            <h1 id="title">MYTHYAVERSE</h1>
          </Link>
        </header>
        <div
          className={`xl:flex xl:overflow-hidden overflow-auto items-center ${
            targetServices === "others" ? "h-screen" : "h-screen"
          }`}
        >
          <div
  className={`xl:w-1/3 xl:mt-0 mt-10 w-full flex flex-col items-start text-white px-10 h-5/6 product-content-panel`}
>
  {ServicesList[targetServices].data.map((services, index) => {
    return (
      <>
        <h1 className="text-4xl mb-10" id={`product-${index}`}>
          {services.title}
        </h1>
        <div
          dangerouslySetInnerHTML={{ __html: services.content }}
          className="mb-10 p-5 text-justify text-lg content-box"
        />
      </>
    );
  })}

  <Link
    to={`/content?section=${1}`}
    className="bg-white text-black text-sm p-4 lg:mt-0 mt-10 hvr-sweep-to-right"
  >
    {"<"} Go back
  </Link>
</div>

          <div className="xl:w-2/3 w-full z-20">
            <Carousel
              items={ServicesList[targetServices].videos}
              callbackCurrentIndex={(i) => {
                setCurrentSlideIndex(i);
              }}
            />
          </div>
          <div className="absolute right-10 bottom-0 top-0">
            <Wormhole isStaticVertical={true} className="z-10" />
          </div>
        </div>
      </div>
    );
  }